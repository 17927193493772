import axios from "axios";

const state = {
  main: {
    products: [],
    lastModified: null
  }
};

const mutations = {
  SET_PRODUCTS(state, payload) {
    state.main.products = payload;
  },
  SET_PRODUCT_QTY(state, payload) {
    state.main.products[payload.index].BstMge = payload.qty;
  },
  SET_PRODUCT_TOTAL(state, payload) {
    state.main.products[payload.index].Gesamt = payload.value;
  },
  SET_LAST_MODIFIED(state, payload) {
    state.main.lastModified = payload;
  }
};

const actions = {
  setProducts({ commit }, products) {
    localStorage.setItem("products", JSON.stringify(products));
    commit("SET_PRODUCTS", JSON.parse(JSON.stringify(products)));
  },
  async loadProducts({ commit, getters }) {
    try {
      let response = await axios.get(
        process.env.VUE_APP_DATA_URL +
          "/" +
          getters.Username +
          "/preisliste.json",
        {
          withCredentials: true,
          auth: {
            username: getters.Username,
            password: getters.Password
          }
        }
      );
      if (response.data) {
        if (response.headers.hasOwnProperty("last-modified")) {
          let lastModified = null;
          try {
            lastModified = new Date(
              Date.parse(response.headers["last-modified"])
            ).toLocaleString();
          } catch (error) {
            lastModified = "N/A";
          }
          commit("SET_LAST_MODIFIED", lastModified);
        }
        for (let p of response.data) {
          p.EK = parseFloat(("" + p.EK).replace(",", "."));
          p.Gewicht = parseFloat(("" + p.Gewicht).replace(",", "."));
          if (p.Mge && !isNaN(p.Mge)) p.Mge = parseInt(p.Mge, 10);
          if (p.Rstd) p.Rstd = parseInt(p.Rstd, 10);
          p.BstMge = 0;
          p.Gesamt = 0;
        }

        let cols = [];
        let filters = {};
        for (let prop in response.data[0]) {
          cols.push(prop);
          filters[prop] = "";
        }
        commit("SET_COLUMN_FILTERS", filters);
        commit("SET_MENU_ITEMS", cols);
        commit("SET_COLUMNS", cols);
        commit("SET_PRODUCTS", response.data);
        commit("SET_LOGGED_IN", true);
        return true;
      }
    } catch (error) {
      throw new Error("Failed to download product data", error);
    }
  },
  clearCart({ state, dispatch }) {
    let cart = state.main.products.filter(p => p.BstMge > 0);
    for (let c of cart) {
      dispatch("setQuantity", { artnr: c.ArtNr, value: 0 });
    }
  },
  async setQuantity({ commit, state }, { artnr, value }) {
    let index = state.main.products.findIndex(p => p.ArtNr === artnr);
    if (index > -1) {
      commit("SET_PRODUCT_QTY", { index: index, qty: value });
      commit("SET_PRODUCT_TOTAL", {
        index: index,
        value: state.main.products[index].EK * value
      });
    }
  },
  async incQuantity({ commit, state }, artnr) {
    let index = state.main.products.findIndex(p => p.ArtNr === artnr);
    if (index > -1) {
      let value = state.main.products[index].BstMge + 1;
      commit("SET_PRODUCT_QTY", { index: index, qty: value });
      commit("SET_PRODUCT_TOTAL", {
        index: index,
        value: state.main.products[index].EK * value
      });
    }
  }
};

const getters = {
  Products: state => state.main.products,
  LastModified: state => {
    if (!state.main.lastModified) return null;
    return state.main.lastModified;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
