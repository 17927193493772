<template>
  <div id="app" :class="{ 'menu-open': MenuOpen, 'cart-open': Cart }">
    <TheNavigation v-if="LoggedIn" />
    <TheMenu v-if="LoggedIn" />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheNavigation from "@/components/TheNavigation";
import TheMenu from "@/components/TheMenu";

export default {
  name: "App",
  components: {
    TheNavigation,
    TheMenu
  },
  watch: {
    LoggedIn(n, o) {
      if (!o && n) this.$router.push("/preisliste");
      else this.$router.replace("/");
    }
  },
  computed: {
    ...mapGetters(["MenuOpen", "Cart", "LoggedIn"])
  }
};
</script>

<style src="spectre.css/dist/spectre.min.css"></style>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Material+Icons");

:root {
  --light-blue: #009fe329;
  --blue: #009fe3;
  --td-hover-color: #009fe329;
  --text-color: #666;
  --gray: #666;
  --grey: #666;
  --lightbox-overlay-bg: #ffffff99;
  --light-grey: #6666664a;
  --light-gray: #6666664a;
  --default-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.15);
}

.form-input:focus {
  border-color: var(--grey);
  box-shadow: 0 0 0 0.1rem rgba(102, 102, 102, 0.2);
}

.form-switch input:checked + .form-icon {
  background: var(--blue);
  border-color: var(--blue);
}

.form-switch input:focus + .form-icon {
  border-color: var(--blue);
}

.btn {
  border: 1px solid var(--grey);
  color: var(--gray);

  &.badge {
    background: #fff;
    &:after {
      background: var(--grey);
    }
  }

  &:hover {
    border: 1px solid var(--grey);
    background: var(--gray);
    color: #fff;
  }
}

ul {
  list-style-type: none;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.badge {
  display: inline-block;
  background: var(--blue);
  padding: 2px 8px;
  border-radius: 2px;
  font-weight: bold;
}

#app {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);

  &.menu-open {
    .the-navigation {
      /*margin-left: 20%;*/
    }
  }
  /*&.cart-open {*/
  /*.the-navigation {*/
  /*width: 80%;*/
  /*}*/
  /*}*/

  .align-right {
    text-align: right;
  }
}
</style>
