import axios from "axios";

const state = {
  main: {
    Currency: {
      Iso: "EUR",
      Name: "Euro",
      Rate: null,
      BaseCurrencyFactor: null,
      ForeignCurrencyFactor: null
    },
    DateModified: "",
    ModUser: "",
    AdrNr: "",
    ExtAdrNr: "",
    Name: "",
    Email: "",
    Vendors: null,
    AdditionalProducts: null,
    DeactivatedProducts: null
  }
};

const mutations = {
  SET_META(state, payload) {
    state.main = payload;
  }
};

const actions = {
  setMeta({ commit }, value) {
    localStorage.setItem("meta", JSON.stringify(value));
    commit("SET_META", JSON.parse(JSON.stringify(value)));
  },
  async loadMeta({ commit, getters }) {
    try {
      let response = await axios.get(
        process.env.VUE_APP_DATA_URL + "/" + getters.Username + "/.meta",
        {
          withCredentials: true,
          auth: {
            username: getters.Username,
            password: getters.Password
          }
        }
      );
      if (response.data) {
        commit("SET_META", response.data);
      }
    } catch (error) {
      return;
    }
  }
};

const getters = {
  Meta: state => state.main,
  Name: state => state.main.Name,
  AdrNr: state => state.main.AdrNr,
  ExtAdrNr: state => state.main.ExtAdrNr,
  CurrencyObj: state => state.main.Currency,
  Currency: state => {
    if (!state.main.Currency) return null;
    return state.main.Currency.Iso === "CCHF" ? "CHF" : state.main.Currency.Iso;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
