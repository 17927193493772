<template>
  <div class="login-view">
    <div class="card">
      <div class="card-header">
        <div class="card-title h2">CSV Frontend</div>
        <div class="card-subtitle text-gray">Anmeldung</div>
      </div>
      <div class="card-body">
        <div v-if="loginFailed !== ''" class="toast toast-error">
          {{ loginFailed }}
        </div>
        <form>
          <div class="form-container">
            <div class="form-group">
              <label class="form-label" for="login-username">Username</label>
              <input
                v-model="username"
                type="text"
                class="form-input"
                id="login-username"
                placeholder="Username"
                autocomplete="username"
              />
            </div>
            <div class="form-group">
              <label class="form-label" for="login-password">Passwort</label>
              <input
                v-model="password"
                type="password"
                class="form-input"
                id="login-password"
                placeholder="Passwort"
                autocomplete="password"
              />
            </div>
            <button
              @click.prevent="performLogin"
              class="btn btn-primary"
              :class="{ loading: LoggingIn }"
            >
              Anmelden
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      loginFailed: ""
    };
  },
  computed: {
    ...mapGetters(["Username", "Password", "LoggingIn"])
  },
  methods: {
    async performLogin() {
      this.setUsername(this.username);
      this.setPassword(this.password);
      try {
        this.setLoggingIn(true);
        await this.loadMeta();
        await this.loadProducts();
        this.setLoggingIn(false);
      } catch (error) {
        this.loginFailed = "Login Fehlgeschlagen. " + error;
        this.setLoggingIn(false);
        //eslint-disable-next-line
        console.error("login failed", error);
      }
    },
    ...mapActions([
      "loadProducts",
      "loadMeta",
      "setUsername",
      "setPassword",
      "setLoggingIn"
    ])
  }
};
</script>

<style lang="scss" scoped>
.login-view {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .card {
    box-shadow: var(--default-shadow);
    border: none;
    padding: 20px 30px;
    width: 500px;

    .btn-primary {
      background: var(--blue);
      border: var(--blue);
      float: right;
    }
  }
}
</style>
