<template>
  <div
    v-if="product"
    class="product-tile tile"
    :class="{ new: product.Neuheit }"
  >
    <div class="tile-icon">
      <div class="tile-icon">
        <img
          :src="'https://ftp.playart.at/csv/images/' + product.ArtNr + '.jpg'"
          class="img-responsive"
        />
      </div>
    </div>
    <div class="tile-content">
      <p class="tile-title">{{ product.Bezeichnung }}</p>
      <p class="tile-subtitle">{{ product.Kategorie }}</p>
      <div class="props-container">
        <template v-for="(value, prop) in product">
          <div
            v-if="!hideProps.includes(prop)"
            @click="$emit('set-column-filter', { column: prop, value: value })"
            class="label"
            :key="'tile-prop-' + product.ArtNr + '-' + prop"
          >
            <strong>{{ prop }}: </strong>{{ value }}
          </div>
        </template>
      </div>
    </div>
    <div class="tile-action">
      <div @click="$emit('set-quantity', 0)" class="btn">
        <i class="material-icons">remove_shopping_cart</i>
      </div>
      <div @click="$emit('inc-quantity')" class="btn">
        <i class="material-icons">add_shopping_cart</i>
      </div>
      <input
        v-if="editing === product.ArtNr"
        v-focus
        type="number"
        :value="product.BstMge"
        @input="$emit('set-quantity', $event)"
        @blur="editing = null"
        min="0"
        class="count form-input"
      />
      <div
        v-else
        @click="editing = product.ArtNr"
        class="count btn badge"
        :data-badge="product.BstMge"
      >
        <i class="material-icons">shopping_cart</i>
      </div>
    </div>
    <div class="price-container">
      <div class="label">{{ product.EK | toCurrency(currency) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductTile",
  props: {
    product: Object,
    currency: String
  },
  data() {
    return {
      editing: null,
      hideProps: ["EK", "Bezeichnung", "Mge", "Neuheit", "BstMge", "Gesamt"]
    };
  }
};
</script>

<style lang="scss" scoped>
.product-tile {
  box-shadow: var(--default-shadow);
  height: 140px;
  margin: 20px;
  position: relative;

  &.new {
    border: 2px solid var(--blue);

    &:after {
      content: "NEU";
      display: block;
      position: absolute;
      top: -15px;
      left: -25px;
      background: var(--blue);
      color: #fff;
      padding: 5px 10px;
    }
  }

  &.tile {
    .tile-icon {
      img {
        max-width: 100px;
        height: auto;
      }
    }

    .tile-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .tile-title {
        margin-bottom: 0;
        font-size: 1.1em;
        font-weight: bold;
      }
      .tile-subtitle {
        color: var(--gray);
        margin-bottom: 5px;
        height: 100%;
      }

      .props-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: auto;
        padding-bottom: 5px;

        > div {
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }

    .price-container {
      position: absolute;
      bottom: -1px;
      right: 0;
      font-size: 1.5em;
    }

    .tile-action {
      display: flex;
      .form-input {
        max-width: 50px;
        display: inline-block;
        border: 1px solid var(--gray);
        margin-left: 5px;
      }

      > div {
        margin-left: 5px;
      }
    }
  }
}
</style>
