import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.filter("toCurrency", (value, currency) => {
  let locale = Intl.NumberFormat().resolvedOptions().locale;
  let result = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency
  }).format(value);
  return result;
});

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
