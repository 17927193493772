var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"product-table table table-striped table-hover"},[_c('thead',[(_vm.Columns)?_c('tr',[_vm._l((_vm.Columns),function(h,i){return _c('th',{key:'header-' + i,class:[
          h,
          {
            sort: h == _vm.Sort,
            asc: _vm.Direction == 'asc',
            desc: _vm.Direction == 'desc'
          }
        ],on:{"click":function($event){return _vm.setSort(h)}}},[_vm._v(" "+_vm._s(h)+" ")])}),_c('th',[_vm._v("Werkzeuge")])],2):_vm._e()]),_c('tbody',_vm._l((_vm.PartialSearchResults),function(p){return _c('ProductTableRow',{key:p.ArtNr,attrs:{"product":p,"columns":_vm.Columns,"currency":_vm.Currency},on:{"set-quantity":function($event){return _vm.setProductQuantity(p.ArtNr, $event)},"inc-quantity":function($event){return _vm.incQuantity(p.ArtNr)},"show-packshot":function($event){return _vm.$emit('show-packshot', $event)},"set-column-filter":function($event){return _vm.setColumnFilter($event)}}})}),1),(_vm.Cart && _vm.CartTotals && _vm.Currency)?_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":_vm.Columns.length - 1}},[_vm._v("Gesamt")]),_c('td',{staticClass:"total"},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.CartTotals,_vm.Currency)))]),_c('td')])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }