const state = {
  main: {
    string: "",
    cart: false,
    backorders: false,
    newItems: false,
    sort: null,
    reverse: false,
    onlyInStock: false,
    columnFilters: {},
    visibleItemSteps: 200,
    visibleItems: 200
  }
};

const mutations = {
  SET_SEARCHSTRING(state, payload) {
    state.main.string = payload;
  },
  SET_CART(state, payload) {
    state.main.cart = payload;
  },
  SET_BACKORDERS(state, payload) {
    state.main.backorders = payload;
  },
  SET_NEWITEMS(state, payload) {
    state.main.newItems = payload;
  },
  SET_SORT(state, payload) {
    state.main.sort = payload;
  },
  SET_REVERSE(state, payload) {
    state.main.reverse = payload;
  },
  SET_ONLY_IN_STOCK(state, payload) {
    state.main.onlyInStock = payload;
  },
  SET_COLUMN_FILTER(state, payload) {
    let filters = { ...state.main.columnFilters };
    filters[payload.column] = payload.value;
    state.main.columnFilters = filters;
  },
  SET_COLUMN_FILTERS(state, filters) {
    state.main.columnFilters = filters;
  },
  CLEAR_COLUMN_FILTERS(state) {
    let filters = { ...state.main.columnFilters };
    for (let f in filters) {
      filters[f] = "";
    }
    state.main.columnFilters = filters;
  },
  SET_VISIBLE_ITEMS(state, payload) {
    state.main.visibleItems = payload;
  }
};

const actions = {
  setSearchString({ commit, dispatch }, value) {
    dispatch("resetVisibleItems");
    commit("SET_SEARCHSTRING", value.trim().toLowerCase());
  },
  clearSearchString({ commit, dispatch }) {
    dispatch("resetVisibleItems");
    commit("SET_SEARCHSTRING", "");
  },
  setCart({ commit, dispatch }, value) {
    dispatch("resetVisibleItems");
    commit("SET_CART", value);
  },
  toggleCart({ commit, state, dispatch }) {
    dispatch("resetVisibleItems");
    commit("SET_CART", !state.main.cart);
  },
  setBackorders({ commit, dispatch }, value) {
    dispatch("resetVisibleItems");
    commit("SET_BACKORDERS", value);
  },
  toggleBackorders({ commit, state, dispatch }) {
    dispatch("resetVisibleItems");
    commit("SET_BACKORDERS", !state.main.backorders);
  },
  setNewItems({ commit, dispatch }, value) {
    dispatch("resetVisibleItems");
    commit("SET_NEWITEMS", value);
  },
  toggleNewItems({ commit, state, dispatch }) {
    dispatch("resetVisibleItems");
    commit("SET_NEWITEMS", !state.main.newItems);
  },
  setOnlyInStock({ commit, dispatch }, value) {
    dispatch("resetVisibleItems");
    commit("SET_ONLY_IN_STOCK", value);
  },
  toggleOnlyInStock({ commit, state, dispatch }) {
    dispatch("resetVisibleItems");
    commit("SET_ONLY_IN_STOCK", !state.main.onlyInStock);
  },
  setSort({ commit, state, dispatch }, value) {
    dispatch("resetVisibleItems");
    if (state.main.sort === value) {
      commit("SET_REVERSE", !state.main.reverse);
    } else {
      commit("SET_SORT", value);
    }
  },
  setReverse({ commit, dispatch }, value) {
    dispatch("resetVisibleItems");
    commit("SET_REVERSE", value);
  },
  toggleDirection({ commit, state, dispatch }) {
    dispatch("resetVisibleItems");
    commit("SET_REVERSE", !state.main.reverse);
  },
  resetSearch({ commit, dispatch }) {
    dispatch("resetVisibleItems");
    commit("SET_REVERSE", false);
    commit("SET_SORT", null);
    commit("SET_CART", false);
    commit("SET_BACKORDERS", false);
    commit("SET_ONLY_NEW", false);
    commit("SET_ONLY_IN_STOCK", false);
    commit("SET_SEARCHSTRING", "");
  },
  setColumnFilter({ commit, dispatch }, payload) {
    if (payload.column !== "BstMge") {
      dispatch("resetVisibleItems");
      commit("SET_COLUMN_FILTER", payload);
    }
  },
  setColumnFilters({ commit }, payload) {
    commit("SET_COLUMN_FILTERS", payload);
  },
  clearColumnFilters({ commit, dispatch }) {
    dispatch("resetVisibleItems");
    commit("CLEAR_COLUMN_FILTERS");
  },
  setVisibleItems({ commit, getters }, value) {
    let max = getters.ResultCount;
    if (value > max) value = max;
    commit("SET_VISIBLE_ITEMS", value);
  },
  resetVisibleItems({ commit, state }) {
    commit("SET_VISIBLE_ITEMS", state.main.visibleItemSteps);
    window.scrollTo(window.scrollX, 0);
  }
};

const getters = {
  SearchString: state => state.main.string,
  ProductList: (state, getters) => {
    let items = [...getters.Products];
    if (state.main.cart) items = items.filter(p => p.BstMge > 0);
    if (state.main.onlyInStock) items = items.filter(p => {
      if (!isNaN(p.Mge)) return (p.Mge > 0);
      if (!p.Mge) return false;
      let parts = p.Mge.split("/");
      if (parts) {
        for (let part of parts) {
          part = part.trim();
          let value = parseInt(part, 10);
          if (value > 0) return true;
        }
      }
      return false;
    });
    if (state.main.backorders) items = items.filter(p => p.Rstd > 0);
    if (state.main.newItems) items = items.filter(p => p.Neuheit);
    for (let col in state.main.columnFilters) {
      if (state.main.columnFilters[col] !== "") {
        items = items.filter(p =>
          ("" + p[col]).includes(state.main.columnFilters[col])
        );
      }
    }
    if (state.main.sort) {
      items.sort((a, b) => {
        let compA = a[state.main.sort] ? a[state.main.sort] : 0;
        let compB = b[state.main.sort] ? b[state.main.sort] : 0;
        if (typeof compA == "string" || typeof compB == "string") {
          let strA = "" + compA;
          let strB = "" + compB;
          return strA.localeCompare(strB);
        } else {
          if (compA > compB) return 1;
          if (compB > compA) return -1;
          if (compA == compB) return 0;
        }
      });
      if (state.main.reverse) items.reverse();
    }
    return items;
  },
  SearchResults: (state, getters) => {
    if (!getters.ProductList) return [];
    if (state.main.string == "" || !state.main.string)
      return getters.ProductList;
    let results = [...getters.ProductList];
    results = results.filter(p => {
      for (let prop in p) {
        if (!p[prop]) continue;
        if (("" + p[prop]).toLowerCase().includes(state.main.string))
          return true;
      }
      return false;
    });
    return results;
  },
  PartialSearchResults: (state, getters) =>
    getters.SearchResults.slice(0, getters.VisibleItems),
  Sort: state => state.main.sort,
  Direction: state => (state.main.reverse ? "desc" : "asc"),
  Cart: state => state.main.cart,
  CartContents: (state, getters) => {
    let items = [...getters.Products];
    items = items.filter(i => i.BstMge > 0);
    return items;
  },
  CartTotals: (state, getters) => {
    let sum = 0;
    for (let item of getters.CartContents) {
      sum += item.EK * item.BstMge;
    }
    return sum;
  },
  OnlyNew: state => state.main.newItems,
  OnlyBackorders: state => state.main.backorders,
  OnlyInStock: state => state.main.onlyInStock,
  ColumnFilters: state => state.main.columnFilters,
  ResultCount: (state, getters) => getters.SearchResults.length,
  VisibleItems: state => state.main.visibleItems
};

export default {
  state,
  mutations,
  actions,
  getters
};
