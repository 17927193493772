<template>
  <div class="home">
    <div class="headline-container">
      <div v-if="Cart" class="headline">Ihr Warenkorb</div>
      <div v-else>Ihre Produkte</div>
      <div class="counts">
        - {{ SearchResults.length }} von {{ Products.length }} Artikel
      </div>
      <div v-if="Cart">zu {{ CartTotals | toCurrency(Currency) }}</div>
      <div class="view-switch">
        <div
          class="btn"
          :class="{ 'btn-primary': mode === 'table' }"
          @click="mode = 'table'"
        >
          <i class="material-icons">view_headline</i>
        </div>
        <div
          class="btn"
          :class="{ 'btn-primary': mode === 'tiles' }"
          @click="mode = 'tiles'"
        >
          <i class="material-icons">view_list</i>
        </div>
        <div
          class="btn"
          :class="{ 'btn-primary': mode === 'cards' }"
          @click="mode = 'cards'"
        >
          <i class="material-icons">view_module</i>
        </div>
      </div>
    </div>
    <div v-if="filtersActive" class="filter-container">
      <div class="chip filter-label">Aktive Filter</div>
      <template v-for="(f, col) in ColumnFilters">
        <div v-if="f != ''" class="chip" :key="'filter-' + col">
          <span
            ><strong>{{ col }}: </strong>{{ f }}</span
          >
          <span
            class="btn btn-clear"
            aria-label="Löschen"
            role="button"
            @click="setColumnFilter({ column: col, value: '' })"
          ></span>
        </div>
      </template>
      <div class="chip clear-label" @click="clearColumnFilters">
        Alle Filter löschen
      </div>
    </div>
    <template v-if="PartialSearchResults.length">
      <div v-if="mode === 'cards'" class="card-container column">
        <ProductCard
          v-for="p of PartialSearchResults"
          :product="p"
          :currency="Currency"
          @set-column-filter="setColumnFilter($event)"
          @inc-quantity="incQuantity(p.ArtNr)"
          @set-quantity="setProductQuantity(p.ArtNr, $event)"
          :key="'card-' + p.ArtNr"
        />
      </div>
      <div v-if="mode === 'tiles'">
        <ProductTile
          v-for="p of PartialSearchResults"
          :product="p"
          :currency="Currency"
          @set-column-filter="setColumnFilter($event)"
          @inc-quantity="incQuantity(p.ArtNr)"
          @set-quantity="setProductQuantity(p.ArtNr, $event)"
          :key="'tile-' + p.ArtNr"
        />
      </div>
      <ProductTable
        v-if="mode === 'table'"
        @show-packshot="packshot = $event"
      />
      <div v-if="Cart" class="send-order-btn-container">
        <div class="btn" @click="toggleCart">Zurück</div>
        <div @click="sendOrder" class="btn btn-primary send-order">
          Bestellung absenden
        </div>
      </div>
    </template>
    <div v-else>
      <div class="empty">
        <div class="empty-icon">
          <i class="material-icons">shopping_cart</i>
        </div>
        <template v-if="Cart">
          <p class="empty-title h5">Hinweis</p>
          <p class="empty-subtitle">Ihr Warenkorb ist derzeit leer.</p>
        </template>
        <template v-else>
          <p class="empty-title h5">Keine Artikel gefunden.</p>
          <p class="empty-subtitle">
            Es wurden keine Artikel gefunden die ihren Filterkriterien
            entsprechen.
          </p>
        </template>
        <div class="empty-action">
          <button class="btn btn-back" @click="toggleCart">Zurück</button>
        </div>
      </div>
    </div>
    <div v-if="packshot" class="lightbox-overlay" @click="packshot = null">
      <img :src="packshot" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "lodash.debounce";
import ProductTable from "@/components/ProductTable";
import ProductCard from "@/components/ProductCard";
import ProductTile from "@/components/ProductTile";

export default {
  name: "home",
  components: {
    ProductTable,
    ProductCard,
    ProductTile
  },
  data() {
    return {
      editing: null,
      packshot: null,
      mode: "table"
    };
  },
  computed: {
    headers() {
      if (!this.Products || this.Products.length < 1) return null;
      let headers = [];
      for (let prop in this.Products[0]) {
        headers.push(prop);
      }
      return headers;
    },
    filtersActive() {
      for (let f in this.ColumnFilters) {
        if (this.ColumnFilters[f] !== "") return true;
      }
      return false;
    },
    ...mapGetters([
      "Products",
      "SearchResults",
      "PartialSearchResults",
      "Meta",
      "Currency",
      "Sort",
      "Direction",
      "Cart",
      "CartTotals",
      "CartContents",
      "Columns",
      "ColumnFilters",
      "VisibleItems",
      "Username",
      "Name",
      "AdrNr",
      "ExtAdrNr",
      "CurrencyObj",
      "AuthHeader"
    ])
  },
  mounted() {
    window.addEventListener("scroll", () => {
      let total = window.document.body.offsetHeight;
      let cur = window.scrollY + window.innerHeight;
      if (total - cur <= 300) {
        this.setVisibleItems(this.VisibleItems + 200);
      }
    });
  },
  watch: {
    Cart(n) {
      if (n) this.mode = "table";
    }
  },
  methods: {
    setProductQuantity: debounce(function(artnr, e) {
      let value = isNaN(e) ? parseInt(e.target.value, 10) : parseInt(e);
      this.setQuantity({ artnr: artnr, value: value });
    }, 300),
    openPackshot(e) {
      e.preventDefault();
    },
    async sendOrder() {
      let order = [];
      if (this.CartContents && this.CartContents.length > 0) {
        for (let item of this.CartContents) {
          order.push({
            ArtNr: item.ArtNr,
            Quantity: item.BstMge,
            EK: item.EK.toFixed(2).replace(".", ","),
            Bez: item.Bezeichnung
          });
        }
      }
      let data = {
        User: this.Username,
        Name: this.Name,
        AdrNr: this.AdrNr,
        ExtAdrNr: this.ExtAdrNr,
        Currency: this.CurrencyObj,
        Order: order
      };
      let result = await fetch(process.env.VUE_APP_API_URL + "/order", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...this.AuthHeader
        },
        body: JSON.stringify(data)
      });
      if (result) {
        let response = await result.json();
        //eslint-disable-next-line
        console.log(response);
        if (response === "success") {
          this.clearCart();
          this.$router.push("/success");
        } else {
          this.$router.push("/fehler");
        }
      }
    },
    ...mapActions([
      "loadProducts",
      "loadMeta",
      "setQuantity",
      "setSort",
      "incQuantity",
      "setColumnFilter",
      "clearColumnFilters",
      "setVisibleItems",
      "toggleCart",
      "clearCart"
    ])
  }
};
</script>

<style lang="scss" scoped>
.home {
  margin-top: 100px;
  box-sizing: border-box;

  .headline-container {
    display: flex;
    font-size: 24px;
    border-bottom: 1px solid #999;
    padding: 0 20px 10px 20px;

    > div {
      margin-right: 5px;
    }

    .view-switch {
      margin-left: auto;
      margin-right: 0;

      .btn {
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }

      .btn-primary {
        background: var(--grey);
        border-color: var(--grey);
      }
    }
  }

  .filter-container {
    display: flex;
    padding-left: 20px;
    margin-top: 20px;
    padding: 0 20px 10px 20px;

    > div {
      margin-right: 5px;
    }

    .chip {
      max-width: unset;
      .btn:hover {
        color: var(--gray);
      }
      &.filter-label {
        background: var(--blue);
        color: #fff;
      }
      &.clear-label {
        background: red;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
  }

  .btn.send-order {
    background: var(--blue);
    border-color: var(--blue);
  }

  .send-order-btn-container {
    display: flex;
    justify-content: flex-end;
    padding: 50px;

    > div {
      margin-right: 5px;
    }
  }

  .total {
    text-decoration: underline double;
  }

  .lightbox-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: var(--lightbox-overlay-bg);
    cursor: pointer;

    img {
      max-height: 80%;
      max-width: 80%;
      box-shadow: 2px 2px 15px var(--light-grey);
    }
  }
}
</style>
