<template>
  <tr v-if="product" class="product-table-row">
    <template v-for="(value, prop) in product">
      <td
        v-if="columns.includes(prop)"
        @click="$emit('set-column-filter', { column: prop, value: value })"
        :class="prop"
        :key="product.ArtNr + '-' + prop"
      >
        <template v-if="prop === 'BstMge'">
          <input
            v-if="editing === product.ArtNr"
            v-focus
            type="number"
            :value="value"
            @input="$emit('set-quantity', $event)"
            @blur="editing = null"
            min="0"
          />
          <div
            v-else
            @click="editing = product.ArtNr"
            class="count btn badge"
            :data-badge="value"
          >
            <i class="material-icons">shopping_cart</i>
          </div>
        </template>
        <template v-else-if="prop === 'EK' || prop === 'Gesamt'">{{
          value | toCurrency(currency)
        }}</template>
        <template v-else-if="prop === 'Neuheit'">
          <div v-if="value" class="label new">NEU</div>
        </template>
        <template v-else>{{ value }}</template>
      </td>
    </template>
    <td class="tools">
      <div>
        <div
          @click="
            $emit(
              'show-packshot',
              'https://ftp.playart.at/csv/images/' + product.ArtNr + '.jpg'
            )
          "
          class="btn"
        >
          <i class="material-icons">photo</i>
        </div>
        <div @click="$emit('set-quantity', 0)" class="btn">
          <i class="material-icons">remove_shopping_cart</i>
        </div>
        <div @click="$emit('inc-quantity')" class="btn">
          <i class="material-icons">add_shopping_cart</i>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "ProductTableRow",
  props: {
    product: Object,
    columns: Array,
    currency: String
  },
  data() {
    return {
      editing: null
    };
  }
};
</script>

<style lang="scss" scoped>
tr.product-table-row {
  td {
    text-align: left;
    padding: 10px 12px;
    font-size: 14px;

    .label.new {
      background: var(--blue);
      color: #fff;
    }

    &.EK,
    &.Gewicht,
    &.Mge,
    &.EAN,
    &.BstMge,
    &.Gesamt,
    &.total {
      text-align: right;
    }

    &.total {
      font-weight: bold;
    }

    &.tools {
      div {
        display: flex;
        .btn {
          margin-right: 10px;
        }
      }
    }

    &:not(.Bezeichnung) {
      white-space: nowrap;
    }

    input[type="number"] {
      max-width: 40px;
      padding: 4px;
    }
  }
}
</style>
