const state = {
  main: {
    items: [],
    forcedColumns: ["BstMge", "Gesamt"],
    columns: [],
    open: false
  }
};

const mutations = {
  SET_MENU_ITEMS(state, payload) {
    state.main.items = payload;
  },
  SET_OPEN(state, payload) {
    state.main.open = payload;
  },
  ADD_MENU_ITEM(state, payload) {
    state.main.items.push(payload);
  },
  ADD_COLUMN(state, payload) {
    state.main.columns.push(payload);
  },
  SET_COLUMNS(state, payload) {
    state.main.columns = payload;
  }
};

const actions = {
  setMenuItems({ commit }, value) {
    commit("SET_MENUITEMS", value);
  },
  setMenuState({ commit }, value) {
    commit("SET_OPEN", value);
  },
  toggleMenu({ commit, state }) {
    commit("SET_OPEN", !state.main.open);
  },
  setColumns({ commit }, cols) {
    commit("SET_COLUMNS", cols);
  },
  toggleColumn({ commit, state }, col) {
    if (!state.main.columns.includes(col)) {
      commit("ADD_COLUMN", col);
    } else {
      let cols = state.main.columns.filter(c => c !== col);
      commit("SET_COLUMNS", cols);
    }
  }
};

const getters = {
  MenuItems: state => state.main.items,
  MenuOpen: state => state.main.open,
  Columns: state => state.main.columns,
  ForcedColumns: state => state.main.forcedColumns
};

export default {
  state,
  mutations,
  actions,
  getters
};
