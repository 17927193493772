<template>
  <div v-if="product" class="card" :class="{ new: product.Neuheit }">
    <div class="card-image packshot">
      <img
        :src="'https://ftp.playart.at/csv/images/' + product.ArtNr + '.jpg'"
        class="img-responsive"
      />
    </div>
    <div class="card-header">
      <div class="card-title h6">{{ product.Bezeichnung }}</div>
      <div class="card-subtitle text-gray">{{ product.Kategorie }}</div>
    </div>
    <div class="card-body">
      <table class="table">
        <template v-for="(value, prop) in product">
          <tr
            v-if="!hideProps.includes(prop)"
            @click="$emit('set-column-filter', { column: prop, value: value })"
            :key="'card-col-' + product.ArtNr + '-' + prop"
          >
            <th>{{ prop }}</th>
            <td>{{ value }}</td>
          </tr>
        </template>
      </table>
    </div>
    <div class="card-footer">
      <div @click="$emit('set-quantity', 0)" class="btn">
        <i class="material-icons">remove_shopping_cart</i>
      </div>
      <div @click="$emit('inc-quantity')" class="btn">
        <i class="material-icons">add_shopping_cart</i>
      </div>
      <input
        v-if="editing === product.ArtNr"
        v-focus
        type="number"
        :value="product.BstMge"
        @input="$emit('set-quantity', $event)"
        @blur="editing = null"
        min="0"
        class="count form-input"
      />
      <div
        v-else
        @click="editing = product.ArtNr"
        class="count btn badge"
        :data-badge="product.BstMge"
      >
        <i class="material-icons">shopping_cart</i>
      </div>
      <div class="label">{{ product.EK | toCurrency(currency) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    product: Object,
    currency: String
  },
  data() {
    return {
      editing: null,
      hideProps: ["EK", "Bezeichnung", "Mge", "Neuheit", "BstMge", "Gesamt"]
    };
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 300px;
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.15);
  margin: 20px;
  border: none;
  position: relative;

  &.new {
    border: 2px solid var(--blue);

    &:after {
      content: "NEU";
      display: block;
      position: absolute;
      top: -15px;
      left: -25px;
      background: var(--blue);
      color: #fff;
      padding: 5px 10px;
    }
  }

  .card-body {
    display: flex;
    table {
      margin-top: auto;
      th,
      td {
        font-size: 12px;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      th {
        border-bottom-width: 1px;
      }

      td {
        text-align: right;
      }
    }
  }

  .card-footer {
    display: flex;

    .label {
      display: inline-flex;
      align-items: center;
      padding: 2px 5px;
    }

    .btn {
      margin-right: 5px;
    }

    .label.new {
      background: var(--blue);
      color: #fff;
    }

    .count {
      margin-right: 5px;
      margin-left: auto;
    }
  }

  .card-image.packshot {
    display: flex;
    justify-content: center;
    height: 300px;

    img {
      object-fit: contain;
    }
  }
}
</style>
