<template>
  <div class="the-navigation">
    <div class="left">
      <ul>
        <li>
          <div
            :class="{ 'burger-button': true, active: MenuOpen }"
            @click="toggleMenu"
          >
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </div>
        </li>
        <li class="logo">
          <img
            src="@/assets/logo_black.png"
            alt="PlayArt Multimedia Handels- GmbH"
          />
        </li>
        <li>Willkommen im JSON Frontend</li>
        <li>Produktstand: {{ LastModified }}</li>
      </ul>
    </div>
    <div class="right">
      <ul>
        <li class="search">
          <div class="has-icon-right">
            <input
              type="text"
              class="form-input"
              :value="SearchString"
              @input="performSearch"
              placeholder="Suchbegriff eingeben..."
            />
            <i class="form-icon material-icons">search</i>
          </div>
        </li>
        <li
          @click="toggleCart"
          class="cart-btn tooltip tooltip-left"
          :data-tooltip="cartTooltip"
        >
          <div class="btn badge" :data-badge="CartContents.length">
            <i class="material-icons">shopping_cart</i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "lodash.debounce";

export default {
  name: "TheNavigation",
  computed: {
    cartTooltip() {
      if (this.CartContents.length < 1) return "Der Warenkorb is leer";
      return (
        this.CartContents.length +
        " Positionen zu " +
        this.$options.filters.toCurrency(this.CartTotals, this.Currency)
      );
    },
    ...mapGetters([
      "SearchString",
      "MenuOpen",
      "LastModified",
      "CartContents",
      "CartTotals",
      "Currency"
    ])
  },
  methods: {
    performSearch: debounce(function(e) {
      this.setSearchString(e.target.value);
    }, 300),
    ...mapActions(["setSearchString", "toggleMenu", "toggleCart"])
  }
};
</script>

<style lang="scss" scoped>
.the-navigation {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px -7px 15px #00000078;
  background: #fff;
  z-index: 100;

  .right {
    margin-left: auto;
    margin-right: 20px;
  }

  ul {
    display: flex;
    align-items: center;

    li {
      list-style-type: none;
      margin: 0 20px 0 0;

      &.logo {
        max-width: 200px;
        padding: 0 20px;
        img {
          width: 100%;
          height: auto;
        }
      }

      &.search {
        .has-icon-right {
          i.form-icon {
            top: 25%;
            transform: translateY(-20%);
            right: 0.35rem;
          }
        }
      }
    }
  }

  .burger-button {
    display: inline-block;
    cursor: pointer;

    .bar1,
    .bar2,
    .bar3 {
      width: 35px;
      height: 5px;
      background-color: #333;
      margin: 6px 0;
      transition: 0.4s;
    }

    &.active {
      .bar1 {
        transform: rotate(-45deg) translate(-9px, 6px);
      }
      .bar2 {
        opacity: 0;
      }
      .bar3 {
        transform: rotate(45deg) translate(-8px, -8px);
      }
    }
  }

  .cart-btn {
    margin: 0 0 0 10px;
    cursor: pointer;
  }
}
</style>
