<template>
  <ul id="the-menu-overlay" class="menu" :class="{ active: MenuOpen }">
    <li class="divider" data-content="Spalten"></li>
    <li v-for="item in MenuItems" class="menu-item" :key="'col-' + item">
      <div class="form-group">
        <label class="form-switch">
          <input
            v-model="Columns"
            type="checkbox"
            :value="item"
            :disabled="ForcedColumns.includes(item)"
          />
          <i class="form-icon"></i> {{ item }}
        </label>
      </div>
    </li>
    <li class="divider" data-content="Filter"></li>
    <li class="menu-item">
      <div class="form-group">
        <label class="form-switch">
          <input v-model="OnlyNew" :value="OnlyNew" type="checkbox" />
          <i class="form-icon"></i> Nur Neuheiten
        </label>
      </div>
    </li>
    <li class="menu-item">
      <div class="form-group">
        <label class="form-switch">
          <input
            v-model="OnlyBackorders"
            :value="OnlyBackorders"
            type="checkbox"
          />
          <i class="form-icon"></i> Nur Rückstände
        </label>
      </div>
    </li>
    <li class="menu-item">
      <div class="form-group">
        <label class="form-switch">
          <input v-model="OnlyInStock" :value="OnlyInStock" type="checkbox" />
          <i class="form-icon"></i> Nur Lagernde Artikel
        </label>
      </div>
    </li>
    <li class="divider" data-content="Export"></li>
    <li class="menu-item">
      <div class="form-group">
        <label class="form-label">
          Trennzeichen
          <input v-model="delimiter" type="text" class="form-input" placeholder=";"/>
        </label>
      </div>
      <div class="form-group">
        <button type="button" class="btn btn-primary" @click="exportAll">Alle Exportieren</button>
      </div>
      <div class="form-group">
        <button type="button" class="btn" @click="exportVisible">Sichtbare Exportieren</button>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Papa from "papaparse";

export default {
  name: "TheMenu",
  data() {
    return {
      cols: [],
      delimiter: '',
    };
  },
  computed: {
    Columns: {
      set(val) {
        this.setColumns(val);
      },
      get() {
        return this.$store.getters.Columns;
      }
    },
    OnlyNew: {
      set(val) {
        this.setNewItems(val);
      },
      get() {
        return this.$store.getters.OnlyNew;
      }
    },
    OnlyBackorders: {
      set(val) {
        this.setBackorders(val);
      },
      get() {
        return this.$store.getters.OnlyBackorders;
      }
    },
    OnlyInStock: {
      set(val) {
        this.setOnlyInStock(val);
      },
      get() {
        return this.$store.getters.OnlyInStock;
      }
    },
    ...mapGetters(["MenuOpen", "MenuItems", "ForcedColumns", "Products", "SearchResults"])
  },
  mounted() {
    this.cols = this.Columns;
  },
  methods: {
    exportAll() {
      const cur = this.delimiter?.trim?.() ?? '';
      const delimiter = (cur === '' || !cur) ? ';' : this.delimiter.trim();
      const products = this.Products.map((c) => {
        const line = { ...c };
        line.EK = this.format(line.EK);
        line.Gewicht = this.format(line.Gewicht);
        line.Gesamt = this.format(line.Gesamt);
        return line;
      });
      const content = Papa.unparse(products, { delimiter });
      this.download(content);
    },
    exportVisible() {
      const cur = this.delimiter?.trim?.() ?? '';
      const delimiter = (cur === '' || !cur) ? ';' : this.delimiter.trim();
      const products = this.SearchResults.map((c) => {
        const line = { ...c };
        line.EK = this.format(line.EK);
        line.Gewicht = this.format(line.Gewicht);
        line.Gesamt = this.format(line.Gesamt);
        return line;
      });
      const content = Papa.unparse(products, { delimiter });
      this.download(content);
    },
    download(content) {
      const blob = new Blob([content], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'playart.csv';
      link.click();
    },
    format(value) {
      const floatValue = parseFloat(value);
      if (isNaN(floatValue)) {
        return value;
      }

      const userLocale = navigator.language || 'de-AT';

      // Format the float using the user's locale
      const formattedValue = new Intl.NumberFormat(userLocale, { maximumFractionDigits: 2 }).format(floatValue);
      return formattedValue;
    },
    ...mapActions([
      "setColumns",
      "setNewItems",
      "setBackorders",
      "setOnlyInStock"
    ])
  }
};
</script>

<style lang="scss" scoped>
#the-menu-overlay {
  position: fixed;
  padding-top: 100px;
  width: 250px;
  height: 100vh;
  background: #ffffffff;
  top: 0;
  z-index: 10;
  left: -250px;
  transition: left 0.5s ease-in-out;
  box-shadow: -7px 0px 15px #00000078;

  h2 {
    font-size: 20px;
    margin-left: 20px;
  }

  &.active {
    left: 0;
  }
}
</style>
