import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import OrderSuccess from "../views/OrderSuccess.vue";
import OrderError from "../views/OrderError.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login-root",
    component: Login
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/success",
    name: "success",
    component: OrderSuccess
  },
  {
    path: "/fehler",
    name: "error",
    component: OrderError
  },
  {
    path: "/preisliste",
    name: "home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (!store.getters.LoggedIn) next("/login");
      else next();
    }
  },
  {
    path: "*",
    component: Login
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
