const state = {
  main: {
    username: "",
    password: "",
    loggedIn: false,
    loggingIn: false
  }
};

const mutations = {
  SET_USERNAME(state, payload) {
    state.main.username = payload;
  },
  SET_PASSWORD(state, payload) {
    state.main.password = payload;
  },
  SET_LOGGED_IN(state, payload) {
    state.main.loggedIn = payload;
  },
  SET_LOGGING_IN(state, payload) {
    state.main.loggingIn = payload;
  }
};

const actions = {
  setUsername({ commit }, value) {
    commit("SET_USERNAME", value);
  },
  setPassword({ commit }, value) {
    commit("SET_PASSWORD", value);
  },
  setLoggedIn({ commit }, value) {
    commit("SET_LOGGED_IN", value);
  },
  setLoggingIn({ commit }, value) {
    commit("SET_LOGGING_IN", value);
  }
};

const getters = {
  User: state => state.main,
  Username: state => state.main.username,
  Password: state => state.main.password,
  LoggedIn: state => state.main.loggedIn,
  LoggingIn: state => state.main.loggingIn,
  AuthHeader: state => {
    if (!state.main.username) return null;
    if (!state.main.password) return null;
    return {
      Authorization:
        "Basic " + btoa(state.main.username + ":" + state.main.password)
    };
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
