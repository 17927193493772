<template>
  <table class="product-table table table-striped table-hover">
    <thead>
      <tr v-if="Columns">
        <th
          v-for="(h, i) of Columns"
          @click="setSort(h)"
          :class="[
            h,
            {
              sort: h == Sort,
              asc: Direction == 'asc',
              desc: Direction == 'desc'
            }
          ]"
          :key="'header-' + i"
        >
          {{ h }}
        </th>
        <th>Werkzeuge</th>
      </tr>
    </thead>
    <tbody>
      <ProductTableRow
        v-for="p of PartialSearchResults"
        :product="p"
        :columns="Columns"
        :currency="Currency"
        :key="p.ArtNr"
        @set-quantity="setProductQuantity(p.ArtNr, $event)"
        @inc-quantity="incQuantity(p.ArtNr)"
        @show-packshot="$emit('show-packshot', $event)"
        @set-column-filter="setColumnFilter($event)"
      />
    </tbody>
    <tfoot v-if="Cart && CartTotals && Currency">
      <tr>
        <td :colspan="Columns.length - 1">Gesamt</td>
        <td class="total">{{ CartTotals | toCurrency(Currency) }}</td>
        <td></td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "lodash.debounce";
import ProductTableRow from "@/components/ProductTableRow";

export default {
  name: "ProductTable",
  components: {
    ProductTableRow
  },
  computed: {
    headers() {
      if (!this.Products || this.Products.length < 1) return null;
      let headers = [];
      for (let prop in this.Products[0]) {
        headers.push(prop);
      }
      return headers;
    },
    ...mapGetters([
      "Columns",
      "PartialSearchResults",
      "Cart",
      "CartTotals",
      "Currency",
      "Direction",
      "Sort"
    ])
  },
  methods: {
    setProductQuantity: debounce(function(artnr, e) {
      let value = isNaN(e) ? parseInt(e.target.value, 10) : parseInt(e);
      this.setQuantity({ artnr: artnr, value: value });
    }, 300),
    ...mapActions(["incQuantity", "setQuantity", "setSort", "setColumnFilter"])
  }
};
</script>

<style lang="scss" scoped>
table.product-table {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  width: 100%;

  th {
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
    color: #999;
    padding: 10px 12px;
    font-size: 14px;

    &.EK,
    &.Gewicht,
    &.Mge,
    &.EAN,
    &.BstMge,
    &.Gesamt,
    &.total {
      text-align: right;
    }

    &.sort {
      color: #000;
      &:before {
      }

      &.desc {
        &:before {
          content: "\1f847";
        }
      }
      &.asc {
        &:before {
          content: "\1f845";
        }
      }
    }
  }

  tfoot {
    td {
      text-align: right;
    }
    td.total {
      text-decoration: underline double;
    }
  }
}
</style>
